import { theme, useResponsive } from "@product/scmp-sdk";
import { Fragment, type ReactNode } from "react";
import { graphql, readInlineData, useLazyLoadQuery } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { Comment } from "scmp-app/components/comment";
import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { Multimedia } from "scmp-app/components/multimedia";
import { PostMagazineDiscoveryWidget } from "scmp-app/components/post-magazine/discovery-widget";
import { TrendingTopic } from "scmp-app/components/trending-topic";
import type { hooksSubSectionParentSectionTrendingTopicQuery } from "scmp-app/queries/__generated__/hooksSubSectionParentSectionTrendingTopicQuery.graphql";
import type { hooksSubSectionWidgetAndAdsQuery$key } from "scmp-app/queries/__generated__/hooksSubSectionWidgetAndAdsQuery.graphql";

import { getSubSectionAdsConfigs, getSubSectionWidgets } from "./helpers";
import {
  CommentContainer,
  FirstAds,
  FourthAds,
  MultimediaContainer,
  RHSModule1,
  RHSModule2,
  RHSModule3,
  RHSModule4,
  SecondAds,
  ThirdAds,
  TrendingTopicContainer,
} from "./styles";
import type { SubSectionWidget } from "./types";

export const useSubSectionAndAdsWidgets = (
  reference: hooksSubSectionWidgetAndAdsQuery$key,
  isShowAdditionWidget: boolean,
) => {
  const data = readInlineData(
    graphql`
      fragment hooksSubSectionWidgetAndAdsQuery on Query
      @inline
      @argumentDefinitions(
        commentQueueName: { type: "String!" }
        entityId: { type: "String!" }
        multimediaQueueName: { type: "String!" }
        parentSectionUuid: { type: "String" }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
      ) {
        parentSection: section(filter: { entityUuid: $parentSectionUuid }) {
          entityId
        }
        ...commentQueueQuery
          @arguments(
            commentLimit: 6
            commentQueueName: $commentQueueName
            scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
            withHarrysView: false
          )
        ...discoveryWidgetPostMagazineQueueQuery
        ...multimediaQuery @arguments(multimediaQueueName: $multimediaQueueName)
        subSection: section(filter: { entityId: $entityId }) {
          entityId
          advertZone(version: 2)
        }
      }
    `,
    reference,
  );

  // Since the trending topic of sub section pages need to use the queue from parent topic instead
  const trendingTopicData = useLazyLoadQuery<hooksSubSectionParentSectionTrendingTopicQuery>(
    graphql`
      query hooksSubSectionParentSectionTrendingTopicQuery($trendingTopicsQueueName: String!) {
        ...trendingTopicTopic @arguments(trendingTopicsQueueName: $trendingTopicsQueueName)
      }
    `,
    {
      trendingTopicsQueueName: `related_topics_${data.parentSection.entityId}`,
    },
    { fetchPolicy: "store-or-network" },
  );

  const adsConfigs = getSubSectionAdsConfigs(
    data.subSection.entityId,
    data?.subSection?.advertZone ?? undefined,
  );

  const renderComment = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "opinion",
        pgtype: "section",
      }}
    >
      <CommentContainer>
        <Comment reference={data} withMoreCommentButton={false} />
      </CommentContainer>
    </BaseLinkContextProvider>
  );
  const renderMultimedia = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "multimedia",
        pgtype: "section",
      }}
    >
      <MultimediaContainer>
        <Multimedia reference={data} />
      </MultimediaContainer>
    </BaseLinkContextProvider>
  );

  const renderTrendingTopics = () => (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "trending_topics",
        pgtype: "section",
      }}
    >
      <TrendingTopicContainer>
        <TrendingTopic reference={trendingTopicData} />
      </TrendingTopicContainer>
    </BaseLinkContextProvider>
  );
  const renderPostMagazine = () => <PostMagazineDiscoveryWidget reference={data} />;

  const rhsModuleComponents = [RHSModule1, RHSModule2, RHSModule3, RHSModule4];
  const adsComponentMap = [FirstAds, SecondAds, ThirdAds, FourthAds];

  const sectionWidgets = getSubSectionWidgets(data.subSection.entityId);
  const sectionWidgetComponentMap: Record<SubSectionWidget, () => ReactNode> = {
    comment: renderComment,
    multimedia: renderMultimedia,
    "post-magazine": renderPostMagazine,
    "trending-topics": renderTrendingTopics,
  };
  const rhsModule = sectionWidgets.map(widget => sectionWidgetComponentMap[widget]);
  const isHomeDesktopUp = useResponsive(theme.breakpoints.up("homeDesktop"), false);
  const render = () => {
    const shouldRenderAdditionalWidgets =
      !isShowAdditionWidget && sectionWidgets.length > 3 && isHomeDesktopUp;

    const widgets = rhsModule.map((rhsModule, index) => {
      const Wrapper = rhsModuleComponents[index];
      if (index > 1 && shouldRenderAdditionalWidgets) return null;

      return (
        <Fragment key={index}>
          <Wrapper>{rhsModule()}</Wrapper>
        </Fragment>
      );
    });

    const ads = adsConfigs.map(([desktopConfig, mobileConfig], index) => {
      const ComponentType = adsComponentMap[index];
      if (index > 2 && shouldRenderAdditionalWidgets) return null;
      return (
        <ComponentType key={`AdSlot${index}`}>
          {desktopConfig && <AdSlot {...desktopConfig} />}
          {mobileConfig && <AdSlot {...mobileConfig} />}
        </ComponentType>
      );
    });

    return [...widgets, ...ads];
  };

  return { render };
};
