import chunk from "lodash/chunk";
import { type FunctionComponent } from "react";
import { graphql, useFragment, useLazyLoadQuery, usePaginationFragment } from "react-relay";

import { useTopBannerAdSlot } from "scmp-app/components/advertisement/ad-slots/top-banner-ad-slot/hooks";
import { AppFooter } from "scmp-app/components/app-footer";
import { BaseLinkContextProvider } from "scmp-app/components/common/base-link/context";
import { EntityOnelineMenu } from "scmp-app/components/entity-oneline-menu";
import { useSectionContext } from "scmp-app/components/section/contexts";
import { SectionNewsletterWidget } from "scmp-app/components/section/section-newsletter-widget";
import { SectionTopWidgetOne } from "scmp-app/components/section/section-top/variant/one";
import { SubscriptionWidget } from "scmp-app/components/subscription-widget";
import { useInfiniteScrollTriggerReference } from "scmp-app/lib/hooks";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { subSectionIndexArticleList$key } from "scmp-app/queries/__generated__/subSectionIndexArticleList.graphql";
import type { subSectionIndexArticleListPaginationQuery } from "scmp-app/queries/__generated__/subSectionIndexArticleListPaginationQuery.graphql";
import type { subSectionParentSectionQuery } from "scmp-app/queries/__generated__/subSectionParentSectionQuery.graphql";
import type { subSectionQuery$key } from "scmp-app/queries/__generated__/subSectionQuery.graphql";

import { getSubSectionWidgets } from "./helpers";
import { useSubSectionAndAdsWidgets } from "./hooks";
import {
  Container,
  ContentContainer,
  ContentWrapper,
  Header,
  HeroArticles,
  Newsletter,
  RelatedTopicContainer,
  StyledEntityLink,
  StyledLoading,
  StyledTopStoriesBlock,
  SubscriptionMessage,
  TopStories1,
  TopStories2,
  TopStories3,
  TopStories4,
  TopStories5,
  TopStories6,
  TopStories7,
  TopStories8,
  TopStoriesRest,
  TopStoriesRestArticle,
} from "./styles";

type TopStoresTypes = typeof TopStories1;

const topStoriesComponentMap: Record<string, TopStoresTypes> = {
  TopStories1,
  TopStories2,
  TopStories3,
  TopStories4,
  TopStories5,
  TopStories6,
  TopStories7,
  TopStories8,
};

export type Props = {
  reference: subSectionQuery$key;
};
export const SubSection: FunctionComponent<Props> = ({ reference: reference_ }) => {
  const data = useFragment(
    graphql`
      fragment subSectionQuery on Query
      @argumentDefinitions(
        entityId: { type: "String!" }
        articlesQueueName: { type: "String!" }
        commentQueueName: { type: "String!" }
        multimediaQueueName: { type: "String!" }
        scmpPlusPaywallTypeIds: { type: "[String]", defaultValue: [] }
        after: { type: "String" }
        first: { type: "Int", defaultValue: 23 }
        parentSectionUuid: { type: "String" }
        relatedTopicsQueueName: { type: "String" }
        hasParentSection: { type: "Boolean!" }
      ) {
        subSection: section(filter: { entityId: $entityId }) {
          name
          entityId
          parentSection
        }
        parentSection: section(filter: { entityUuid: $parentSectionUuid })
          @include(if: $hasParentSection) {
          name
          entityId
          ...entityLink
        }
        relatedTopics: queue(filter: { name: $relatedTopicsQueueName }) {
          items {
            edges {
              ...entityOnelineMenuQueueItemsEdge
            }
          }
        }
        articles: queue(filter: { name: $articlesQueueName }) {
          ...subSectionIndexArticleList @arguments(after: $after, first: $first)
        }
        ...hooksSubSectionWidgetAndAdsQuery
          @arguments(
            commentQueueName: $commentQueueName
            entityId: $entityId
            multimediaQueueName: $multimediaQueueName
            parentSectionUuid: $parentSectionUuid
            scmpPlusPaywallTypeIds: $scmpPlusPaywallTypeIds
          )
        ...sectionNewsletterWidgetQuery @arguments(entityId: $entityId)
      }
    `,
    reference_,
  );

  const { advertisement: baseAdvertisement } = useSectionContext();
  const currentPageType = useCurrentPageType();
  useTopBannerAdSlot({
    desktop: {
      adUnit: "d_banner1",
      sizes: [[970, 250], [728, 90], [970, 90], "fluid"],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [data.subSection.entityId],
      },
      zone: baseAdvertisement.zone,
    },
    mobile: {
      adUnit: "m_banner3",
      sizes: [
        [300, 100],
        [320, 100],
        [300, 50],
        [320, 50],
      ],
      targeting: {
        ...baseAdvertisement.targeting,
        scsid: [data.subSection.entityId],
      },
      zone: baseAdvertisement.zone,
    },
  });
  const {
    data: articlesWithPaging,
    hasNext: hasNextPage,
    isLoadingNext: isLoading,
    loadNext,
  } = usePaginationFragment<
    subSectionIndexArticleListPaginationQuery,
    subSectionIndexArticleList$key
  >(
    graphql`
      fragment subSectionIndexArticleList on Queue
      @argumentDefinitions(after: { type: "String" }, first: { type: "Int", defaultValue: 23 })
      @refetchable(queryName: "subSectionIndexArticleListPaginationQuery") {
        items(first: $first, after: $after)
          @connection(key: "subSectionIndexArticleListQuery__items") {
          edges {
            ...oneSectionTopWidgetQueue
            ...topStoriesBlockQueueItemsEdge
          }
        }
      }
    `,
    data?.articles,
  );

  const parentSectionData = useLazyLoadQuery<subSectionParentSectionQuery>(
    graphql`
      query subSectionParentSectionQuery($entityUuid: String) {
        section(filter: { entityUuid: $entityUuid }) {
          entityId
        }
      }
    `,
    {
      entityUuid: data.subSection.parentSection,
    },
    { fetchPolicy: "store-or-network" },
  );

  const parentSectionEntityId = parentSectionData?.section?.entityId ?? "";

  const pageType = useCurrentPageType();

  const { infiniteScrollTriggerReference } = useInfiniteScrollTriggerReference({
    hasNextPage,
    isLoading,
    onLoadMore: () => loadNext(8),
  });

  const topStories = articlesWithPaging?.items?.edges ?? [];
  const isShowAdditionalWidget = topStories.length > 31;
  const { render: subSectionWidgets } = useSubSectionAndAdsWidgets(data, isShowAdditionalWidget);
  const numberOfSubSectionWidgets = getSubSectionWidgets(data.subSection.entityId).length;

  if (topStories.length === 0) return null;

  /** [1st, 2nd, 3rd] */
  const heroArticlesGroupSize = 3;
  const heroArticlesGroup =
    topStories.length > heroArticlesGroupSize
      ? topStories.slice(0, heroArticlesGroupSize)
      : topStories;

  const groupSize = 4;
  const chunkedArticlesGroup = chunk(topStories.slice(heroArticlesGroupSize), groupSize);

  /** [[4th, 5th, 6th, 7th], ..., [24th, 25th, 26th, 27th]] */
  const topStoriesGroupNumber = numberOfSubSectionWidgets > 3 ? 8 : 6;
  const topStoriesComponents = chunkedArticlesGroup
    .slice(0, topStoriesGroupNumber)
    .map((group, index) => ({
      content: group,
      type: `TopStories${index + 1}`,
    }));

  /** [[28th, 29th, 30th, 31th], ...] */
  const restStoriesComponents = chunkedArticlesGroup.slice(topStoriesGroupNumber);
  const relatedTopics = data?.relatedTopics?.items?.edges ?? [];

  return (
    <Container>
      <ContentContainer>
        <ContentWrapper
          $isShowAds={topStoriesComponents.length > 6}
          $parentSectionEntityId={parentSectionEntityId}
          $subSectionEntityId={data.subSection.entityId}
        >
          <Header>
            {data?.parentSection && (
              <StyledEntityLink
                query={{
                  module: "sub_section_menu",
                  pgtype: currentPageType,
                }}
                reference={data.parentSection}
              >
                {data?.parentSection?.name} /
              </StyledEntityLink>
            )}
            <h1>{data?.subSection?.name}</h1>
            <BaseLinkContextProvider
              customQueryParameters={{
                module: "related_topics",
                pgtype: currentPageType,
              }}
            >
              {relatedTopics.length > 0 && (
                <RelatedTopicContainer>
                  <EntityOnelineMenu
                    extraSlide={<div>Related Topics:</div>}
                    reference={relatedTopics}
                  />
                </RelatedTopicContainer>
              )}
            </BaseLinkContextProvider>
          </Header>

          <BaseLinkContextProvider
            customQueryParameters={{
              module: "top_story",
              pgtype: pageType,
            }}
          >
            <HeroArticles>
              {heroArticlesGroup && (
                <SectionTopWidgetOne
                  reference={heroArticlesGroup}
                  primaryWithSection
                  onClick={() => {}}
                />
              )}
            </HeroArticles>

            {topStoriesComponents.map((topStories, index) => {
              const ComponentType = topStoriesComponentMap[topStories.type];
              if (!topStories.content) {
                return null;
              }
              return (
                <ComponentType key={topStories.type + index}>
                  <StyledTopStoriesBlock reference={topStories.content} onClick={() => {}} />
                </ComponentType>
              );
            })}
            <TopStoriesRest>
              {restStoriesComponents.map((restStores, index) => {
                if (!restStores) return null;
                return (
                  <TopStoriesRestArticle key={"rest" + index}>
                    <StyledTopStoriesBlock reference={restStores} onClick={() => {}} />
                  </TopStoriesRestArticle>
                );
              })}
              {hasNextPage && (
                <StyledLoading ref={element => infiniteScrollTriggerReference(element)} />
              )}
            </TopStoriesRest>
          </BaseLinkContextProvider>
          {subSectionWidgets()}
          <BaseLinkContextProvider
            customQueryParameters={{
              module: "sub_msg_1",
              pgtype: pageType,
            }}
          >
            <SubscriptionMessage>
              <SubscriptionWidget />
            </SubscriptionMessage>
          </BaseLinkContextProvider>
          {!hasNextPage && (
            <Newsletter>
              <SectionNewsletterWidget reference={data} />
            </Newsletter>
          )}
        </ContentWrapper>
      </ContentContainer>
      {!hasNextPage && <AppFooter variant="section" />}
    </Container>
  );
};

SubSection.displayName = "SubSection";
