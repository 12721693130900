/**
 * @generated SignedSource<<0de8dd132f2afe07d8144ab9d259bb47>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hooksSubSectionWidgetAndAdsQuery$data = {
  readonly parentSection: {
    readonly entityId: string;
  };
  readonly subSection: {
    readonly advertZone: string | null | undefined;
    readonly entityId: string;
  };
  readonly " $fragmentSpreads": FragmentRefs<"commentQueueQuery" | "discoveryWidgetPostMagazineQueueQuery" | "multimediaQuery">;
  readonly " $fragmentType": "hooksSubSectionWidgetAndAdsQuery";
};
export type hooksSubSectionWidgetAndAdsQuery$key = {
  readonly " $data"?: hooksSubSectionWidgetAndAdsQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"hooksSubSectionWidgetAndAdsQuery">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "hooksSubSectionWidgetAndAdsQuery"
};

(node as any).hash = "e5d091b18876773e73db28a2d5befda4";

export default node;
